/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import ContractDesignStudio, {
  IContractDesignStudioData,
} from '~/componentsV2/contract-design-studio'

type Props = {}

type QueryResult = {
  pageData: {
    frontmatter: IContractDesignStudioData
    html: string
  }
}

const CustomRugStudioTemplate: FC<Props> = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "custom-rug-studio" } }) {
        frontmatter {
          templateKey
          overlayColor
          heroTitle
          heroSize
          heroBackground {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }

          contentTitle
          content
          contentInfo
          contentCall

          formTitle
          formID

          bigImage {
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }

          contacts {
            title
            info
          }

          serviceTitle

          services {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 520, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
          carousel {
            image {
              childImageSharp {
                gatsbyImageData(width: 1440, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        html
      }
    }
  `)

  return <ContractDesignStudio data={query.pageData.frontmatter} />
}

export default CustomRugStudioTemplate
